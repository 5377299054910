import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import {
  ALL,
  FACEBOOK,
  INSTAGRAM,
  PLATFORM_LIST,
  PLATFORM_LIST_NAMES,
  PLATFORM_LIST_WITH_ALL,
  TWITTER,
  WHATSAPP,
  YOUTUBE
} from '../../../config/constants';
import {
  POST_TYPE_KEY,
  POST_TYPE_MESSAGE,
  POST_TYPE_POST_KEY,
  POST_TYPE_REEL_KEY,
  POST_TYPE_SHORT_VIDEO_KEY,
  POST_TYPE_STATUS,
  POST_TYPE_STORY_KEY,
  POST_TYPE_TO_PREFIX,
  POST_TYPE_VIDEO_KEY,
  POST_TYPE_CHOICES_MAP,
  platformFeatures,
  POST_TYPE_CHOICE_POST
} from '../../../components/lomavisCreator/config/platformFeatures';
import { PLATFORM_STATIC_ICONS_PNG } from '../../../config/icons';
import twitterText from 'twitter-text';

import { readLomavisCreatorConfig } from './lomavisCreatorConfig';
import {
  ACTIONS_VALUES_MAP,
  DEFUALT_INPUT_TIME,
  PREVIEW_TYPE_STANDARD
} from '../../../components/lomavisCreator/config/constants';
import axiosClient from '../../../utils/axios';
import modalStates from '../modals/modalStates';
import { platform } from 'os';
import {
  fromArrayStringToArr,
  fromArrayToArrString,
  getVideoThumbnailUrl
} from './utils';
import { extractTimeFromRecurrence } from '../../../components/lomavisCreator/utils/helpers';

export const MEDIA_UPLOAD = 'mediaUpload';
export const MEDIA_REORDERING = 'mediaReordering';

const savedLomavisCreatorConfig = readLomavisCreatorConfig();

const initialState = {
  uuid: '',
  quillEditors: {},
  created_in_lomavis: true,
  tempState: { creator_multipost_config: {} },
  multipost_use_instagram_default_location: false,
  postIdea: null,
  multipost_copied_to_profile_group_for_approval: false,
  post_status: null,
  has_been_published: false,
  cropsCache: {},
  lomavisCreatorConfig: readLomavisCreatorConfig(),
  platformEnabled: PLATFORM_LIST.reduce((acc, platform) => {
    acc[platform] =
      savedLomavisCreatorConfig?.supported_platforms[platform] &&
      savedLomavisCreatorConfig?.connected_platforms[platform];
    return acc;
  }, {}),
  locationUuid: savedLomavisCreatorConfig?.location?.uuid,
  cloudUuid: savedLomavisCreatorConfig?.cloud?.uuid,
  loadedMediaFiles: savedLomavisCreatorConfig?.load_media_files,
  postTypeSelected: PLATFORM_LIST.reduce((acc, platform) => {
    acc[platform] = POST_TYPE_POST_KEY;
    if (platform === YOUTUBE) {
      acc[platform] = POST_TYPE_VIDEO_KEY;
    }
    if (platform === WHATSAPP) {
      acc[platform] = POST_TYPE_MESSAGE;
    }
    return acc;
  }, {}),
  postData: PLATFORM_LIST_WITH_ALL.reduce((postData, platform) => {
    postData[platform] = {
      // adding additional fields
      url_for_linkpreview: null,
      show_preview_url: true,
      first_comment: null,
      // load default values from lomavisCreatorConfig if given else set to null
      instagram_location_id:
        platform == INSTAGRAM
          ? savedLomavisCreatorConfig?.creator_defaults?.instagram_location_id
          : null,
      instagram_location_name:
        platform == INSTAGRAM
          ? savedLomavisCreatorConfig?.creator_defaults?.instagram_location_name
          : null,
      facebook_location_name:
        platform == FACEBOOK
          ? savedLomavisCreatorConfig?.creator_defaults?.facebook_location_id
          : null,
      facebook_location_id:
        platform == FACEBOOK
          ? savedLomavisCreatorConfig?.creator_defaults?.facebook_location_id
          : null,
      is_facebook_reel: false,
      is_facebook_story: false,
      is_instagram_reel: false,
      is_instagram_story: false,
      instagram_user_tags: [],
      google_cta_type: null,
      google_cta_url: null,
      youtube_tags: [],
      youtube_title: null,
      youtube_category: null,
      youtube_privacy_status: null,
      youtube_available_for_kids: null,
      linkedin_document_title: null,
      media: [],
      cropList: [],
      unEditedMedia: [],
      mediaUploading: [],
      draggedMediaFiles: [],
      mediaLibraryFiles: [],
      text: { ops: [{ insert: '' }] },
      text_as_html: '',
      text_as_text: '',
      text_length: 0,
      text_length_striped: 0,
      plannedPublicationDate: null,
      recurrence: null,
      planned_publication_datetime_date: null,
      planned_publication_datetime_time: DEFUALT_INPUT_TIME,
      planned_publication_datetime: null,
      tempPublicationData: {
        date: null,
        time: DEFUALT_INPUT_TIME,
        datetime: null
      },
      urlPreview: null,
      source_image: {
        uuid: ''
      },
      thumbnail_image: {
        uuid: ''
      },
      uploadedImageThumbnail: {
        url: '',
        file: null,
        uploadPresets: null,
        uploaded: false
      },
      editedImageThumbnail: {
        url: '',
        file: null,
        uploadPresets: null,
        uploaded: false,
        media: {}
      },
      fullEditorImage: {
        url: '',
        file: null,
        uploadPresets: null,
        uploaded: false,
        currentCrop: null
      },
      layeredImageThumbnail: {
        file: null,
        uploadPresets: null,
        uploaded: false,
        media: {}
      },
      video_thumbnail: {},
      video_thumbnail_editor: {},
      video_thumbnail_background_image: '',

      thumbnail_preview_processing: false
    };
    if (platform === INSTAGRAM) {
      postData[platform].instagram_user_tags = Array(10).fill([]);
      postData[platform].instagram_collaborators = [];

      postData[platform].instagram_temp_tags = [];

      postData[platform].allowTagModalClose = false;
    }
    return postData;
  }, {}),
  mediaFiles: [],

  assetUploading: false,
  recurrence_as_str: '',
  time: DEFUALT_INPUT_TIME,
  timeZone: savedLomavisCreatorConfig?.timezone,
  is_multipost: savedLomavisCreatorConfig?.is_multipost,
  // is_multipost: true,
  is_recurring_post: false,
  general_post_type: POST_TYPE_KEY,
  publication_delay_in_minutes: undefined,
  useSeparateMediaFiles: false,
  useSeparateTextFields: false,
  use_separate_publication_datetime: false,
  use_separate_publication_datetime_date: false,
  use_separate_publication_datetime_time: false,
  all_platforms_publication_datetime_date: null,
  all_platforms_publication_datetime_time: '',
  uploadedImageThumbnail: null,
  allowEditorClose: false,
  allowMultiImageEditor: false,
  allowThumbnailEditorClose: true,
  creator_multipost_config: {},
  lastCropUpdateTimestamp: null,
  selectionFilters: {
    byPlatform: [],
    byTag: []
  },
  ui: {
    showOnlySelectedPlatforms: false,
    showProfileGroupSelectModal: false,
    scheduleModalToggle: false,
    showEditImageModal: false,
    previewMode: PREVIEW_TYPE_STANDARD,
    showPostIdeaModal: false,
    uploadAreaVisible: true
  },
  schedulePlanningData: {
    time: '12:15',
    recurrence_as_str: '',
    is_recurring_post: false,
    publication_delay_in_minutes: undefined
  },
  publishingDelayData: {
    days: null,
    hours: null,
    minutes: null,
    totalMinutes: null,
    noPublishDays: [],
    noPublishStartTime: '',
    noPublishEndTime: ''
  },
  temporaryPublishingDelayData: {
    days: null,
    hours: null,
    minutes: null,
    totalMinutes: null,
    noPublishDays: [],
    noPublishStartTime: '',
    noPublishEndTime: ''
  },

  modalStates: {
    schedule: {
      toggle: false
    },
    thumbnail: {
      toggle: false,
      source: '',
      platform: ALL
    },
    mediaEditor: {
      toggle: false,
      platform: ALL,
      currentIndex: null
    },
    multiMediaEditor: {
      toggle: false,
      platform: ALL
    },
    IGTags: {
      toggle: false,
      platform: ALL
    }
  },
  uploadCancelToken: null
};

const lomavisCreatorSlice = createSlice({
  name: 'lomavisCreator',
  initialState,
  reducers: {
    updatePlatformEnabled: (
      state,
      action: PayloadAction<{ platform: string; enabled: boolean }>
    ) => {
      // check that at least one platform is active
      if (!action.payload.enabled) {
        const areAtLeastTwoPlatformsEnabled =
          PLATFORM_LIST.map(
            (platform) => state.platformEnabled[platform]
          ).filter((isEnabled) => isEnabled === true).length >= 2;

        if (!areAtLeastTwoPlatformsEnabled) {
          // Do not allow disabling all platforms
          return;
        }
      }
      // check if we currently enabling platform and use_separate_text_fields is enabled
      if (action.payload.enabled && !state.useSeparateTextFields) {
        // move the from ALL to the new platform postData
        const allPostData = { ...state.postData[ALL] };
        // move text related fields
        state.postData[action.payload.platform].text = allPostData.text;
        state.postData[action.payload.platform].text_as_html =
          allPostData.text_as_html;
        state.postData[action.payload.platform].text_length =
          allPostData.text_length;
        state.postData[action.payload.platform].text_length_striped =
          allPostData.text_length_striped;
        // move url preview
        state.postData[action.payload.platform].urlPreview =
          allPostData.urlPreview;
        // move url for linkpreview
        state.postData[action.payload.platform].url_for_linkpreview =
          allPostData.url_for_linkpreview;

        // TODO TO move media and publication date

        state.postData[action.payload.platform].first_comment = null;
        state.postData[action.payload.platform].instagram_location_id =
          allPostData.instagram_location_id;
        state.postData[action.payload.platform].instagram_location_name =
          allPostData.instagram_location_name;
        state.postData[action.payload.platform].facebook_location_name =
          allPostData.facebook_location_name;
        state.postData[action.payload.platform].facebook_location_id =
          allPostData.facebook_location_id;
        state.postData[action.payload.platform].is_facebook_reel =
          allPostData.is_facebook_reel;
        state.postData[action.payload.platform].is_facebook_story =
          allPostData.is_facebook_story;
        state.postData[action.payload.platform].is_instagram_reel =
          allPostData.is_instagram_reel;
        state.postData[action.payload.platform].is_instagram_story =
          allPostData.is_instagram_story;
        state.postData[action.payload.platform].instagram_user_tags =
          allPostData.instagram_user_tags;
        state.postData[action.payload.platform].google_cta_type =
          allPostData.google_cta_type;
        state.postData[action.payload.platform].google_cta_url =
          allPostData.google_cta_url;
        state.postData[action.payload.platform].youtube_tags =
          allPostData.youtube_tags;
        state.postData[action.payload.platform].youtube_title =
          allPostData.youtube_title;
        state.postData[action.payload.platform].youtube_category =
          allPostData.youtube_category;
        state.postData[action.payload.platform].youtube_privacy_status =
          allPostData.youtube_privacy_status;
        state.postData[action.payload.platform].youtube_available_for_kids =
          allPostData.youtube_available_for_kids;
        state.postData[action.payload.platform].linkedin_document_title =
          allPostData.linkedin_document_title;
        state.postData[action.payload.platform].media = allPostData.media;
        state.postData[action.payload.platform].cropList = allPostData.cropList;
        state.postData[action.payload.platform].unEditedMedia = [];
        state.postData[action.payload.platform].mediaUploading = [];
        state.postData[action.payload.platform].plannedPublicationDate =
          allPostData.plannedPublicationDate;
        state.postData[action.payload.platform].recurrence =
          allPostData.recurrence;
        state.postData[action.payload.platform].planned_publication_datetime =
          allPostData.planned_publication_datetime;
        state.postData[
          action.payload.platform
        ].planned_publication_datetime_date =
          allPostData.planned_publication_datetime_date;
        state.postData[
          action.payload.platform
        ].planned_publication_datetime_time =
          allPostData.planned_publication_datetime_time;
        state.postData[action.payload.platform].tempPublicationData =
          allPostData.tempPublicationData;
        state.postData[action.payload.platform].source_image =
          allPostData.source_image;
        state.postData[action.payload.platform].thumbnail_image =
          allPostData.thumbnail_image;
        state.postData[action.payload.platform].uploadedImageThumbnail =
          allPostData.uploadedImageThumbnail;
        state.postData[action.payload.platform].editedImageThumbnail =
          allPostData.editedImageThumbnail;
        state.postData[action.payload.platform].fullEditorImage =
          allPostData.fullEditorImage;
        state.postData[action.payload.platform].layeredImageThumbnail =
          allPostData.layeredImageThumbnail;
        state.postData[action.payload.platform].video_thumbnail =
          allPostData.video_thumbnail;
        state.postData[action.payload.platform].video_thumbnail_editor =
          allPostData.video_thumbnail_editor;
        state.postData[
          action.payload.platform
        ].video_thumbnail_background_image =
          allPostData.video_thumbnail_background_image;
        state.postData[action.payload.platform].thumbnail_preview_processing =
          allPostData.thumbnail_preview_processing;

        // state.postData[action.payload.platform] = {
        //   ...allPostData,
        //   unEditedMedia: [],
        //   mediaUploading: [],
        // };
      }
      state.platformEnabled[action.payload.platform] = action.payload.enabled;
    },
    isPlatformEnabled: (state, action: PayloadAction<{ platform: string }>) => {
      return state.platformEnabled[action.payload.platform];
    },
    isPlatformSupported: (
      state,
      action: PayloadAction<{ platform: string }>
    ) => {
      return state.lomavisCreatorConfig.supported_platforms[
        action.payload.platform
      ];
    },
    updatePostTypeSelected: (
      state,
      action: PayloadAction<{ platform: string; postType: string }>
    ) => {
      state.postTypeSelected[action.payload.platform] = action.payload.postType;

      // set all affected fields to default values. Then activate the ones that are needed
      // this is needed to update the selected post type such as reel or story
      const fields_to_reset = []
        .concat(
          Object.keys(platformFeatures[action.payload.platform]).map((x) =>
            platformFeatures[action.payload.platform][x].map(
              (y) => y.dataFieldEnable
            )
          )
        )
        .flat()
        .filter(Boolean);

      for (const field of fields_to_reset) {
        state.postData[action.payload.platform][field] = false;
      }

      // enable the field that are needed for the selected post type
      console.log(state.general_post_type, 'general post type');
      platformFeatures[action.payload.platform][state.general_post_type]
        .filter((x) => x.typeKey === action.payload.postType)
        .map((x) =>
          x.dataFieldEnable?.forEach((field) => {
            state.postData[action.payload.platform][field] = true;
          })
        );
    },
    getPostTypeSelected: (
      state,
      action: PayloadAction<{ platform: string }>
    ) => {
      return state.postTypeSelected[action.payload.platform];
    },
    updateUseSeparateMediaFiles: (state, action: PayloadAction<boolean>) => {
      state.useSeparateMediaFiles = action.payload;
    },
    updateUseSeparateTextFields: (state, action: PayloadAction<boolean>) => {
      state.useSeparateTextFields = action.payload;
    },
    updateUseSeparatePublicationDateTime: (
      state,
      action: PayloadAction<boolean>
    ) => {
      state.use_separate_publication_datetime = action.payload;
    },
    addMediaFiles: (
      state,
      action: PayloadAction<{ platform: string | null; files: any[] }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData[action.payload.platform].media = [
        ...state.postData[action.payload.platform].media,
        ...action.payload.files
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].media = [
              ...state.postData[p].media,
              ...action.payload.files
            ];
          }
        }
      }
    },

    addUnEditedMediaFiles: (
      state,
      action: PayloadAction<{ platform: string | null; files: any[] }>
    ) => {
      const existingMediaIds = new Set(
        state.postData[action.payload.platform].unEditedMedia.map(
          (file) => file.id
        )
      );

      // Filter out files that already exist
      const newFiles = action.payload.files.filter(
        (file) => !existingMediaIds.has(file.id)
      );

      state.postData[action.payload.platform].unEditedMedia = [
        ...state.postData[action.payload.platform].unEditedMedia,
        ...newFiles
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            const existingMediaIdsForPlatform = new Set(
              state.postData[p].unEditedMedia.map((file) => file.id)
            );

            const newFilesForPlatform = action.payload.files.filter(
              (file) => !existingMediaIdsForPlatform.has(file.id)
            );

            state.postData[p].unEditedMedia = [
              ...state.postData[p].unEditedMedia,
              ...newFilesForPlatform
            ];
          }
        }
      }
    },

    clearEditedMediafile: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.postData[action.payload.platform].unEditedMedia = [];

      state.postData[action.payload.platform].media.map((item) => {
        item.editorUpdateState = false;
      });

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].unEditedMedia = [];
          state.postData[p].media.map((item) => {
            item.editorUpdateState = false;
          });
        }
      }
    },
    deleteUnEditedMediafile: (
      state,
      action: PayloadAction<{ platform: string | null; fileID: any }>
    ) => {
      state.postData[action.payload.platform].unEditedMedia = [
        ...state.postData[action.payload.platform].unEditedMedia.filter(
          (obj) => obj.id !== action.payload.fileID
        )
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].unEditedMedia = [
            ...state.postData[p].unEditedMedia.filter(
              (obj) => obj.id !== action.payload.fileID
            )
          ];
        }
      }
    },
    reorderMediaFiles: (
      state,
      action: PayloadAction<{ platform: string | null; newOrder: any[] }>
    ) => {
      const { newOrder, platform } = action.payload;

      // Helper function to reorder media by id array and append unsorted items at the end
      const reorderAllMediaArray = (mediaArray, reorderedIds) => {
        // Map reordered IDs to corresponding media items

        const reorderedMedia = reorderedIds
          .map((uuid) =>
            mediaArray.find((item) =>
              item.source_media_uuid
                ? item?.source_media_uuid === uuid
                : item?.uuid === uuid
            )
          )
          .filter(Boolean); // Filter out any undefined values

        // Find any media items that were not in the reorderedIds and add them at the end
        const unsortedMedia = mediaArray.filter(
          (item) =>
            !reorderedIds.includes(item.uuid) &&
            !reorderedIds.includes(item.source_media_uuid)
        );

        return [...reorderedMedia, ...unsortedMedia];
      };
      const reorderMediaArray = (mediaArray, reorderedIds) => {
        // Map reordered IDs to corresponding media items

        const reorderedMedia = reorderedIds
          .map((uuid) => mediaArray.find((item) => item?.uuid === uuid))
          .filter(Boolean); // Filter out any undefined values

        // Find any media items that were not in the reorderedIds and add them at the end
        const unsortedMedia = mediaArray.filter(
          (item) => !reorderedIds.includes(item.uuid)
        );

        return [...reorderedMedia, ...unsortedMedia];
      };

      // Reorder for a specific platform

      state.postData[platform].media = reorderMediaArray(
        state.postData[platform].media,
        newOrder
      );

      // Reorder for all platforms if 'ALL' is specified
      if (platform === ALL) {
        for (const p of PLATFORM_LIST_WITH_ALL) {
          if (state.platformEnabled[p]) {
            state.postData[p].media = reorderAllMediaArray(
              state.postData[p].media,
              newOrder
            );
          }
        }
      }
    },

    addMediaUploadingFiles: (
      state,
      action: PayloadAction<{ platform: string | null; files: any[] }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData[action.payload.platform].mediaUploading = [
        ...state.postData[action.payload.platform].mediaUploading,
        ...action.payload.files
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].mediaUploading = [
              ...state.postData[p].mediaUploading,
              ...action.payload.files
            ];
          }
        }
      }
    },
    clearUnuploadedFiles: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.postData[action.payload.platform].mediaUploading = [];

      state.postData[action.payload.platform].media = state.postData[
        action.payload.platform
      ].media.filter((object) => object.uploaded !== false);

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].mediaUploading = [];

            state.postData[p].media = state.postData[p].media.filter(
              (object) => object.uploaded !== false
            );
          }
        }
      }
    },
    deleteAfile: (
      state,
      action: PayloadAction<{ platform: string | null; fileID: any; uuid: any }>
    ) => {
      state.postData[action.payload.platform].media = [
        ...state.postData[action.payload.platform].media.filter(
          (obj) => obj.id !== action.payload.fileID
        )
      ];
      state.postData[action.payload.platform].media = [
        ...state.postData[action.payload.platform].media.filter(
          (obj) => obj?.source_media_uuid !== action.payload.fileID
        )
      ];
      state.postData[action.payload.platform].unEditedMedia = [
        ...state.postData[action.payload.platform].unEditedMedia?.filter(
          (obj) => obj?.id !== action.payload.fileID
        )
      ];

      state.postData[action.payload.platform].cropList = [
        ...state.postData[action.payload.platform].cropList.filter(
          (obj) => obj.uuid !== action.payload.uuid
        )
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].media = [
            ...state.postData[p].media.filter(
              (obj) => obj.id !== action.payload.fileID
            )
          ];

          state.postData[p].media = [
            ...state.postData[p].media.filter(
              (obj) => obj?.source_media_uuid !== action.payload.fileID
            )
          ];

          state.postData[p].unEditedMedia = [
            ...state.postData[p].unEditedMedia?.filter(
              (obj) => obj?.id !== action.payload.fileID
            )
          ];
          state.postData[p].cropList = [
            ...state.postData[p].cropList.filter(
              (obj) => obj.uuid !== action.payload.uuid
            )
          ];
        }
      }
    },
    deleteALoadingfile: (
      state,
      action: PayloadAction<{ platform: string | null; fileID: any }>
    ) => {
      state.postData[action.payload.platform].mediaUploading = [
        ...state.postData[action.payload.platform].mediaUploading.filter(
          (obj) => obj.id !== action.payload.fileID
        )
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].mediaUploading = [
            ...state.postData[p].mediaUploading.filter(
              (obj) => obj.id !== action.payload.fileID
            )
          ];
        }
      }
    },

    fileUploadStatus: (
      state,
      action: PayloadAction<{ platform: string | null; fileID: any }>
    ) => {
      state.postData[action.payload.platform].media = state.postData[
        action.payload.platform
      ].media.map((obj) => {
        if (obj.id === action.payload.fileID) {
          // Update the mediaType of the file with the specified fileID
          return {
            ...obj,
            uploaded: true // Set the new media_type here
          };
        }
        // Return unchanged object for other files
        return obj;
      });

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].media = state.postData[p].media.map((obj) => {
            if (obj.id === action.payload.fileID) {
              // Update the mediaType of the file with the specified fileID
              return {
                ...obj,
                uploaded: true // Set the new mediaType here
              };
            }
            // Return unchanged object for other files
            return obj;
          });
        }
      }
    },

    addDraggedFiles: (
      state,
      action: PayloadAction<{ platform: string | null; files: any[] }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData[action.payload.platform].draggedMediaFiles = [
        ...action.payload.files
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].draggedMediaFiles = [...action.payload.files];
          }
        }
      }
    },
    removeDraggedFiles: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.postData[action.payload.platform].draggedMediaFiles = [];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].draggedMediaFiles = [];
          }
        }
      }
    },

    addMediaLibraryFiles: (
      state,
      action: PayloadAction<{ platform: string | null; files: any[] }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?

      state.postData[action.payload.platform].mediaLibraryFiles = [
        ...action.payload.files
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].mediaLibraryFiles = [...action.payload.files];
          }
        }
      }
    },
    removeMediaLibraryFiles: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.postData[action.payload.platform].mediaLibraryFiles = [];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].mediaLibraryFiles = [];
          }
        }
      }
    },

    addPublicationDate: (
      state,
      action: PayloadAction<{ platform: string | null; date: any }>
    ) => {
      const { platform, date } = action.payload;

      state.postData[platform].tempPublicationData.date = date;

      if (platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].tempPublicationData.date = date;
        }

        state.all_platforms_publication_datetime_date = action.payload.date;
      }
    },

    addPublicationTime: (
      state,
      action: PayloadAction<{ platform: string | null; time: any }>
    ) => {
      const { platform, time } = action.payload;

      state.postData[platform].tempPublicationData.time = time;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].tempPublicationData.time = time;
        }

        state.all_platforms_publication_datetime_time = action.payload.time;
      }
    },

    clearPublicationDateTime: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?
      const { platform } = action.payload;
      state.postData[platform].tempPublicationData = {
        date: '',
        time: '12:15',
        datetime: null
      };

      // state.postData[platform
      // ].planned_publication_datetime_time = "";
      // state.postData[platform
      // ].planned_publication_datetime_date = null;
      // state.postData[platform].planned_publication_datetime =
      //   null;

      if (platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].tempPublicationData = {
            date: '',
            time: '12:15',
            datetime: null
          };

          // state.postData[p].planned_publication_datetime_time = "";
          // state.postData[p].planned_publication_datetime_date = null;
          // state.postData[p].planned_publication_datetime = null;
        }

        state.all_platforms_publication_datetime_time = '';
        state.all_platforms_publication_datetime_date = null;
      }
    },

    addPublicationDateTime: (
      state,
      action: PayloadAction<{ platform: string | null; dateTime: any }>
    ) => {
      // TODO: Clear uploading media files when the upload is finished
      // Where will I handle the upload? In the component or in the slice?
      const { platform, dateTime } = action.payload;
      state.postData[platform].tempPublicationData.datetime = dateTime;

      // state.postData[platform].planned_publication_datetime =
      //   dateTime;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].tempPublicationData.datetime = dateTime;
          // state.postData[p].planned_publication_datetime =
          //  dateTime;
        }

        state.all_platforms_publication_datetime_time = action.payload.dateTime;
      }
    },

    addMainPublicationDateTime: (
      state,
      action: PayloadAction<{
        platform: string | null;
        dateTime: any;
      }>
    ) => {
      const { platform, dateTime } = action.payload;

      if (dateTime) {
        // Format the full dateTime (ISO 8601 with timezone offset)
        const formattedDateTimeWithOffset = dateTime?.toISOString();

        const date = new Date(formattedDateTimeWithOffset);
        // Format the date (YYYY-MM-DD)
        const formattedDate = date.toISOString().split('T')[0];
        // Format the time (HH:mm) in local time
        const formattedTime = date.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        });

        state.postData[platform].planned_publication_datetime = dateTime;
        state.postData[platform].planned_publication_datetime_time =
          formattedTime;
        state.postData[platform].planned_publication_datetime_date =
          formattedDate;

        state.postData[platform].tempPublicationData.date = formattedDate;
        state.postData[platform].tempPublicationData.time = formattedTime;
        state.postData[platform].tempPublicationData.datetime = dateTime;

        if (action.payload.platform === ALL) {
          // General media upload has been executed
          for (const p of PLATFORM_LIST) {
            state.postData[p].planned_publication_datetime = dateTime;
            state.postData[p].planned_publication_datetime_time = formattedTime;
            state.postData[p].planned_publication_datetime_date = formattedDate;

            state.postData[p].tempPublicationData.date = formattedDate;
            state.postData[p].tempPublicationData.time = formattedTime;
            state.postData[p].tempPublicationData.datetime = dateTime;

            state.postData[p].planned_publication_datetime = dateTime;
          }

          state.all_platforms_publication_datetime_time =
            action.payload.dateTime;
        }
      }
    },

    addTime: (state, action: PayloadAction<{ Time: any }>) => {
      state.schedulePlanningData.time = action.payload.Time;
    },
    addPublicationDelay: (state, action: PayloadAction<{ Time: any }>) => {
      state.schedulePlanningData.publication_delay_in_minutes =
        action.payload.Time;
    },
    addRecurringStr: (state, action: PayloadAction<{ recurrence: string }>) => {
      state.schedulePlanningData.recurrence_as_str = action.payload.recurrence;
    },

    updatePublishingDelayData: (
      state,
      action: PayloadAction<{
        attribute: string;
        value: any;
      }>
    ) => {
      state.temporaryPublishingDelayData[action.payload.attribute] =
        action.payload.value;
    },

    clearRecurrenceData: (state, action: PayloadAction<{}>) => {
      state.schedulePlanningData.time = '12:15';
      state.schedulePlanningData.recurrence_as_str = '';
      state.schedulePlanningData.is_recurring_post = false;
      state.schedulePlanningData.publication_delay_in_minutes = '';
    },
    clearPublishingDelayData: (state, action: PayloadAction<{}>) => {
      state.temporaryPublishingDelayData.noPublishEndTime = '';
      state.temporaryPublishingDelayData.noPublishStartTime = '';
      state.temporaryPublishingDelayData.noPublishDays = [];
      state.temporaryPublishingDelayData.days = null;
      state.temporaryPublishingDelayData.hours = null;
      state.temporaryPublishingDelayData.minutes = null;
      state.temporaryPublishingDelayData.totalMinutes = null;
    },

    updateRecurringStatus: (state, action: PayloadAction<{}>) => {
      const { time, recurrence_as_str, is_recurring_post } =
        state.schedulePlanningData;

      if (time && recurrence_as_str) {
        state.schedulePlanningData.is_recurring_post = true;
      } else {
        state.schedulePlanningData.is_recurring_post = false;
      }
    },
    toggleActivePlatform: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      if (state.creator_multipost_config[key]) {
        delete state.creator_multipost_config[key];
      } else state.creator_multipost_config[key] = true;
    },
    toggleActivePlatformTemp: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      if (state.tempState.creator_multipost_config[key]) {
        delete state.tempState.creator_multipost_config[key];
      } else state.tempState.creator_multipost_config[key] = true;
    },
    removeActivePlatform: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      if (state.creator_multipost_config[key]) {
        delete state.creator_multipost_config[key];
      }
    },
    updatePostKey: (
      state,
      action: PayloadAction<{ key: string; value: any }>
    ) => {
      state[action.payload.key] = action.payload.value;
    },
    updatePostDataKey: (
      state,
      action: PayloadAction<{
        platform: string | null;
        key: string;
        value: any;
      }>
    ) => {
      state.postData[action.payload.platform][action.payload.key] =
        action.payload.value;
    },
    removeActivePlatformTemp: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      if (state.tempState.creator_multipost_config[key]) {
        delete state.tempState.creator_multipost_config[key];
      }
    },
    addActivePlatform: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      console.log('adding active platform,');
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      state.creator_multipost_config[key] = true;
    },
    addActivePlatformTemp: (
      state,
      action: PayloadAction<{ platform: string; locationUUid: string }>
    ) => {
      const key = `${action.payload.locationUUid}_${action.payload.platform}`;
      state.tempState.creator_multipost_config[key] = true;
    },
    resetActiveLocations: (state, action: PayloadAction<{}>) => {
      state.creator_multipost_config = {};
    },
    resetActiveLocationsTemp: (state, action: PayloadAction<{}>) => {
      state.tempState.creator_multipost_config = {};
    },
    updatePostText: (
      state,
      action: PayloadAction<{
        platform: string | null;
        text: string;
        text_as_html: string;
        textText: string;
        urlPreview: any;
        url_for_linkpreview?: any;
      }>
    ) => {
      state.postData[action.payload.platform].text_as_text =
        action.payload.textText;
      state.postData[action.payload.platform].text = action.payload.text;

      state.postData[action.payload.platform].text_as_html =
        action.payload.text_as_html;
      if (action.payload.platform === TWITTER) {
        const tweet = twitterText.parseTweet(action.payload.textText.trim());

        state.postData[action.payload.platform].text_length =
          tweet.weightedLength;
        state.postData[action.payload.platform].text_length_striped =
          tweet.weightedLength;
      } else {
        state.postData[action.payload.platform].text_length =
          action.payload.textText.length;
        state.postData[action.payload.platform].text_length_striped =
          action.payload.textText.trim().length;
      }

      state.postData[action.payload.platform].urlPreview =
        action.payload.urlPreview;
      state.postData[action.payload.platform].url_for_linkpreview =
        action.payload.url_for_linkpreview;
    },
    updateUrlPreviewData: (
      state,
      action: PayloadAction<{
        platform: string | null;
        data: any;
        url_for_linkpreview: string | null;
      }>
    ) => {
      state.postData[action.payload.platform].urlPreview = action.payload.data;
      state.postData[action.payload.platform].url_for_linkpreview =
        action.payload.url_for_linkpreview;
    },

    addImageThumbnail: (
      state,
      action: PayloadAction<{
        url: any;
        file: any;
        platform: string | null;
      }>
    ) => {
      state.uploadedImageThumbnail = action.payload.url;

      state.postData[action.payload.platform].uploadedImageThumbnail.url =
        action.payload.url;
      state.postData[action.payload.platform].video_thumbnail_background_image =
        action.payload.url;
      state.postData[action.payload.platform].uploadedImageThumbnail.file =
        action.payload.file;

      state.postData[action.payload.platform].editedImageThumbnail.url =
        action.payload.url;
      state.postData[action.payload.platform].editedImageThumbnail.file =
        action.payload.file;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].uploadedImageThumbnail.url = action.payload.url;
          state.postData[p].video_thumbnail_background_image =
            action.payload.url;
          state.postData[p].editedImageThumbnail.url = action.payload.url;
          state.postData[p].uploadedImageThumbnail.file = action.payload.file;
          state.postData[p].editedImageThumbnail.file = action.payload.file;
        }
      }
    },

    editImageThumbnail: (
      state,
      action: PayloadAction<{ url: any; file: any; platform: string | null }>
    ) => {
      state.postData[action.payload.platform].editedImageThumbnail.url =
        action.payload.url;
      state.postData[action.payload.platform].editedImageThumbnail.file =
        action.payload.file;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].editedImageThumbnail.url = action.payload.url;
          state.postData[p].editedImageThumbnail.file = action.payload.file;
        }
      }
    },
    clearImageThumbnail: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.uploadedImageThumbnail = '';

      const postDataObj = state.postData[action.payload.platform];

      postDataObj.uploadedImageThumbnail.url = '';
      postDataObj.uploadedImageThumbnail.file = null;
      postDataObj.uploadedImageThumbnail.uploadPresets = null;
      postDataObj.uploadedImageThumbnail.media = {};

      postDataObj.editedImageThumbnail.url = '';
      postDataObj.editedImageThumbnail.file = null;
      postDataObj.editedImageThumbnail.uploadPresets = null;

      postDataObj.layeredImageThumbnail.file = null;
      postDataObj.layeredImageThumbnail.uploaded = false;
      postDataObj.layeredImageThumbnail.uploadPresets = null;
      postDataObj.layeredImageThumbnail.media = {};
      postDataObj.fullEditorImage.uploaded = false;
      postDataObj.fullEditorImage.url = '';
      postDataObj.fullEditorImage.file = null;

      postDataObj.video_thumbnail = {};
      postDataObj.video_thumbnail_editor = {};
      postDataObj.video_thumbnail_background_image = '';

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].uploadedImageThumbnail.url = '';
          state.postData[p].uploadedImageThumbnail.file = null;
          state.postData[p].uploadedImageThumbnail.uploadPresets = null;
          state.postData[p].uploadedImageThumbnail.media = {};
          state.postData[p].editedImageThumbnail.url = '';
          state.postData[p].editedImageThumbnail.file = null;
          state.postData[p].editedImageThumbnail.uploadPresets = null;

          state.postData[p].layeredImageThumbnail.file = null;
          state.postData[p].layeredImageThumbnail.uploaded = false;
          state.postData[p].layeredImageThumbnail.uploadPresets = null;
          state.postData[p].layeredImageThumbnail.media = {};
          state.postData[p].fullEditorImage.uploaded = false;
          state.postData[p].fullEditorImage.url = '';
          state.postData[p].fullEditorImage.file = null;

          state.postData[p].video_thumbnail = {};
          state.postData[p].video_thumbnail_editor = {};
          state.postData[p].video_thumbnail_background_image = '';
        }
      }
    },
    cancelImageThumbnail: (
      state,
      action: PayloadAction<{ platform: string | null }>
    ) => {
      state.uploadedImageThumbnail = '';

      const postDataObj = state.postData[action.payload.platform];

      postDataObj.uploadedImageThumbnail.url = '';
      postDataObj.uploadedImageThumbnail.file = null;
      postDataObj.uploadedImageThumbnail.uploadPresets = null;
      postDataObj.uploadedImageThumbnail.media = {};

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].uploadedImageThumbnail.url = '';
          state.postData[p].uploadedImageThumbnail.file = null;
          state.postData[p].uploadedImageThumbnail.uploadPresets = null;
          state.postData[p].uploadedImageThumbnail.media = {};
        }
      }
    },

    layerImageThumbnail: (
      state,
      action: PayloadAction<{ file: any; platform: string | null }>
    ) => {
      state.postData[action.payload.platform].layeredImageThumbnail.file =
        action.payload.file;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].layeredImageThumbnail.file = action.payload.file;
        }
      }
    },

    updateUploadedImagePresets: (
      state,
      action: PayloadAction<{ presets: any; platform: string | null }>
    ) => {
      state.postData[
        action.payload.platform
      ].uploadedImageThumbnail.uploadPresets = action.payload.presets;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].uploadedImageThumbnail.uploadPresets =
            action.payload.presets;
        }
      }
    },
    updateEditorImagePresets: (
      state,
      action: PayloadAction<{ presets: any; platform: string | null }>
    ) => {
      state.postData[action.payload.platform].fullEditorImage.uploadPresets =
        action.payload.presets;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].fullEditorImage.uploadPresets =
            action.payload.presets;
        }
      }
    },
    updateEditedImagePresets: (
      state,
      action: PayloadAction<{ presets: any; platform: string | null }>
    ) => {
      state.postData[
        action.payload.platform
      ].editedImageThumbnail.uploadPresets = action.payload.presets;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].editedImageThumbnail.uploadPresets =
            action.payload.presets;
        }
      }
    },

    updateLayeredImagePresets: (
      state,
      action: PayloadAction<{
        presets: any;
        platform: string | null;
      }>
    ) => {
      state.postData[
        action.payload.platform
      ].layeredImageThumbnail.uploadPresets = action.payload.presets;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].layeredImageThumbnail.uploadPresets =
            action.payload.presets;
        }
      }
    },
    updateLayeredImageState: (
      state,
      action: PayloadAction<{
        uploadState: boolean;
        platform: string | null;
        media: any;
      }>
    ) => {
      const { media, platform, uploadState } = action.payload;
      state.postData[action.payload.platform].layeredImageThumbnail.uploaded =
        action.payload.uploadState;
      state.postData[action.payload.platform].layeredImageThumbnail.media =
        action.payload.media;

      state.postData[action.payload.platform].video_thumbnail = media;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].layeredImageThumbnail.uploaded =
            action.payload.uploadState;
          state.postData[p].layeredImageThumbnail.media = action.payload.media;
          state.postData[p].video_thumbnail = media;
        }
      }
    },

    updateEditedImageState: (
      state,
      action: PayloadAction<{
        uploadState: boolean;
        platform: string | null;
        media: any;
      }>
    ) => {
      const { media, platform, uploadState } = action.payload;
      state.postData[action.payload.platform].editedImageThumbnail.uploaded =
        action.payload.uploadState;
      state.postData[action.payload.platform].editedImageThumbnail.media =
        action.payload.media;

      state.postData[action.payload.platform].video_thumbnail_editor = media;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].editedImageThumbnail.uploaded =
            action.payload.uploadState;
          state.postData[p].editedImageThumbnail.media = action.payload.media;
          state.postData[p].video_thumbnail_editor = media;
        }
      }
    },

    saveEditorFullImage: (
      state,
      action: PayloadAction<{
        url: any;
        file: any;
        currentCrop: any;
        platform: string | null;
        uploadState: boolean;
      }>
    ) => {
      state.postData[action.payload.platform].fullEditorImage.url =
        action.payload.url;
      state.postData[action.payload.platform].fullEditorImage.file =
        action.payload.file;
      state.postData[action.payload.platform].fullEditorImage.currentCrop =
        action.payload.currentCrop;
      state.postData[action.payload.platform].fullEditorImage.uploaded =
        action.payload.uploadState;
      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].fullEditorImage.url = action.payload.url;
          state.postData[p].fullEditorImage.file = action.payload.file;
          state.postData[p].fullEditorImage.currentCrop =
            action.payload.currentCrop;
          state.postData[p].fullEditorImage.uploaded =
            action.payload.uploadState;
        }
      }
    },

    updateThumbnailProcessing: (
      state,
      action: PayloadAction<{
        state: boolean;
        platform: string | null;
      }>
    ) => {
      state.postData[action.payload.platform].thumbnail_preview_processing =
        action.payload.state;

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          state.postData[p].thumbnail_preview_processing = action.payload.state;
        }
      }
    },

    updateMediaAssetFile: (
      state,
      action: PayloadAction<{
        file: any;
        preview_image: string;
        assetBlob: any;
        uuid: string | null;
        platform: string | null;
        uploadPresets: any;
        source_media_uuid: any;
        width: string | number;
        height: string | number;
      }>
    ) => {
      const {
        platform,
        uuid,
        file,
        preview_image,
        assetBlob,
        uploadPresets,
        source_media_uuid,
        width,
        height
      } = action.payload;
      const mediaItem = state.postData[platform].media.find(
        (obj) => obj.uuid === uuid
      );

      mediaItem.file = file;
      mediaItem.preview_image =
        uploadPresets?.uploaded_object_data.preview_image_url;
      mediaItem.assetBlob = assetBlob;
      mediaItem.uploadPreset = uploadPresets;
      mediaItem.source_media_uuid = source_media_uuid;

      mediaItem.uuid = uploadPresets?.uuid;
      mediaItem.id = uploadPresets?.uuid;
      mediaItem.previewImageURL =
        uploadPresets?.uploaded_object_data.preview_image_url;

      mediaItem.thumbnail_image = uploadPresets?.thumbnail_url;
      mediaItem.original_asset =
        uploadPresets?.uploaded_object_data.original_asset;
      mediaItem.width = width;
      mediaItem.height = height;

      state.postData[platform].cropList = [
        ...state.postData[platform].cropList.filter((obj) => obj.uuid !== uuid)
      ];

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            const platformMediaItem = state.postData[p]?.media?.find(
              (obj) => obj?.uuid === uuid
            );
            platformMediaItem.file = file;
            platformMediaItem.preview_image =
              uploadPresets?.uploaded_object_data.preview_image_url;
            platformMediaItem.assetBlob = assetBlob;
            platformMediaItem.uploadPreset = uploadPresets;
            platformMediaItem.source_media_uuid = source_media_uuid;

            platformMediaItem.uuid = uploadPresets?.uuid;
            platformMediaItem.id = uploadPresets?.uuid;
            platformMediaItem.previewImageURL =
              uploadPresets?.uploaded_object_data.preview_image_url;
            platformMediaItem.thumbnail_image = uploadPresets?.thumbnail_url;
            platformMediaItem.original_asset =
              uploadPresets?.uploaded_object_data.original_asset;
            platformMediaItem.width = width;
            platformMediaItem.height = height;

            state.postData[p].cropList = [
              ...state.postData[p].cropList.filter((obj) => obj.uuid !== uuid)
            ];
          }
        }
      }
    },

    updateEditorUpdateState: (
      state,
      action: PayloadAction<{
        platform: string | null;
        state: boolean;
        uuid: string | null;
      }>
    ) => {
      state.postData[action.payload.platform].media.find(
        (obj) => obj.uuid === action.payload.uuid
      ).editorUpdateState = action.payload.state;
    },

    updateEditorCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.allowEditorClose = action.payload.state;
    },
    updateThumbnailCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.allowThumbnailEditorClose = action.payload.state;
    },

    updateMultiImageEditorCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.allowMultiImageEditor = action.payload.state;
    },
    updateIGTagModalCloseState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.postData[INSTAGRAM].allowTagModalClose = action.payload.state;
    },
    updatePlatformSelectionFilter: (
      state,
      action: PayloadAction<{
        platform: string;
      }>
    ) => {
      if (state.selectionFilters.byPlatform.includes(action.payload.platform)) {
        state.selectionFilters.byPlatform =
          state.selectionFilters.byPlatform.filter(
            (platform) => platform !== action.payload.platform
          );
      } else state.selectionFilters.byPlatform.push(action.payload.platform);
    },
    updateTagSelectionFilter: (
      state,
      action: PayloadAction<{
        tag: string;
      }>
    ) => {
      if (state.selectionFilters.byTag.includes(action.payload.tag)) {
        state.selectionFilters.byTag = state.selectionFilters.byTag.filter(
          (tag) => tag !== action.payload.tag
        );
      } else state.selectionFilters.byTag.push(action.payload.tag);
    },
    setShowOnlySelectedPlatforms: (
      state,
      action: PayloadAction<{ showOnlySelectedPlatforms: boolean }>
    ) => {
      state.ui.showOnlySelectedPlatforms =
        action.payload.showOnlySelectedPlatforms;
    },
    setUploadAreaVisibility: (
      state,
      action: PayloadAction<{ visible: boolean }>
    ) => {
      state.ui.uploadAreaVisible = action.payload.visible;
    },
    setShowProfileGroupSelectModal: (state, action: PayloadAction<boolean>) => {
      if (action.payload) {
        state.tempState.creator_multipost_config =
          state.creator_multipost_config;
      }
      state.ui.showProfileGroupSelectModal = action.payload;
    },
    syncProfileGroupSelectState: (state) => {
      state.creator_multipost_config = {
        ...state.tempState.creator_multipost_config
      };
    },
    setScheduleModalToggle: (state, action: PayloadAction<boolean>) => {
      state.ui.scheduleModalToggle = action.payload;
    },
    setShowEditImageModal: (state, action: PayloadAction<boolean>) => {
      state.ui.showEditImageModal = action.payload;
    },
    setGeneralPostType: (state, action: PayloadAction<string | null>) => {
      state.general_post_type = action.payload;
    },
    setPlatformFirstComment: (
      state,
      action: PayloadAction<{ platform: string; firstComment: string }>
    ) => {
      state.postData[action.payload.platform].first_comment =
        action.payload.firstComment;
    },

    setSliceAttribute: (
      state,
      action: PayloadAction<{
        attribute: string;
        value: any;
      }>
    ) => {
      state[action.payload.attribute] = action.payload.value;
    },
    setPostDataAttribute: (
      state,
      action: PayloadAction<{
        platform: string;
        attribute: string;
        value: any;
      }>
    ) => {
      state.postData[action.payload.platform][action.payload.attribute] =
        action.payload.value;

      if (action.payload.platform === ALL) {
        // General media upload has been executed

        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p][action.payload.attribute] = action.payload.value;
          }
        }
      }
    },

    updateIGTags: (
      state,
      action: PayloadAction<{
        uuid: any;
        newTags: any[];
      }>
    ) => {
      const { uuid, newTags } = action.payload;
      const index = state.postData[INSTAGRAM].instagram_temp_tags.findIndex(
        (obj) => obj.uuid === uuid
      );

      if (index !== -1) {
        // If uuid exists, update the tags of the existing object
        state.postData[INSTAGRAM].instagram_temp_tags[index].tags = newTags;
      } else {
        // If uuid doesn't exist, create a new object with the uuid and tags
        state.postData[INSTAGRAM].instagram_temp_tags = [
          ...state.postData[INSTAGRAM].instagram_temp_tags,
          { uuid, tags: newTags }
        ];
      }
    },

    deleteATag: (
      state,
      action: PayloadAction<{
        uuid: any;
        tagIndex: number;
      }>
    ) => {
      const { uuid, tagIndex } = action.payload;
      const objectIndex = state.postData[
        INSTAGRAM
      ].instagram_temp_tags.findIndex((obj) => obj.uuid === uuid);

      if (objectIndex !== -1) {
        // If uuid exists, remove the tag at the specified index
        state.postData[INSTAGRAM].instagram_temp_tags[objectIndex].tags =
          state.postData[INSTAGRAM].instagram_temp_tags[
            objectIndex
          ].tags.filter((_, index) => index !== tagIndex);
      }
    },

    addIGCollaborator: (
      state,
      action: PayloadAction<{
        newCollaborator: any;
      }>
    ) => {
      state.postData[INSTAGRAM].instagram_collaborators = [
        ...state.postData[INSTAGRAM].instagram_collaborators,
        action.payload.newCollaborator
      ];
    },
    deleteACollaborator: (
      state,
      action: PayloadAction<{
        collabIndex: number;
      }>
    ) => {
      const { collabIndex } = action.payload;
      if (
        collabIndex >= 0 &&
        collabIndex < state.postData[INSTAGRAM].instagram_collaborators.length
      ) {
        state.postData[INSTAGRAM].instagram_collaborators = state.postData[
          INSTAGRAM
        ].instagram_collaborators.filter((_, index) => index !== collabIndex);
      }
    },
    loadPostToState: (state, action: PayloadAction<any>) => {
      // loop through postData keys and update the state
      const postData = action.payload.postData;

      // it can happen that these lists are saved in the backend as null values. To address this,
      // we need to check if they are null and set them to empty arrays if they are
      for (const key in postData) {
        for (const field of [
          'instagram_collaborators',
          'instagram_user_tags'
        ]) {
          if (!postData[field]) continue;

          if (!postData[field]?.instagram_collaborators) {
            postData[field].instagram_collaborators = [];
          }
        }

        state.postData[key] = { ...state.postData[key], ...postData[key] };
      }
      // loop through keys and update the state if key exists
      for (const key in action.payload) {
        if (key === 'postData') continue;
        if (key in state) {
          state[key] = action.payload[key];
        }
      }
    },
    saveCroppedImageCache: (
      state,
      action: PayloadAction<{ key: string; obj: any }>
    ) => {
      state.cropsCache[action.payload.key] = action.payload.obj;
    },

    setMediaDataAttribute: (
      state,
      action: PayloadAction<{
        platform: string;
        uuid: string;
        attribute: string;
        value: boolean;
      }>
    ) => {
      state.postData[action.payload.platform].media.find(
        (obj) => obj.uuid === action.payload.uuid
      )[action.payload.attribute] = action.payload.value;

      if (action.payload.platform === ALL) {
        // General media upload has been executed

        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            state.postData[p].media.find(
              (obj) => obj.uuid === action.payload.uuid
            )[action.payload.attribute] = action.payload.value;
          }
        }
      }
    },
    updateCancelToken: (
      state,
      action: PayloadAction<{
        token: any;
      }>
    ) => {
      state.uploadCancelToken = action.payload.token;
    },

    addCropObject: (
      state,
      action: PayloadAction<{ platform: string | null; cropInfo: any }>
    ) => {
      const { cropInfo, platform } = action.payload;

      // It can happen that we have -0 for 0. Fixing it, because we cannot have negative pixels
      cropInfo.x = Math.abs(cropInfo.x);
      cropInfo.y = Math.abs(cropInfo.y);

      const aspectRatioWidth = cropInfo.aspect_ratio_width;
      const aspectRatioHeight = cropInfo.aspect_ratio_height;

      function closestIntegerHeightWidth(startWidth) {
        let widthAbove = startWidth;
        let widthBelow = startWidth;

        // Find closest width above that results in an integer height
        while (true) {
          const heightAbove =
            (widthAbove * aspectRatioHeight) / aspectRatioWidth;
          if (Number.isInteger(heightAbove)) {
            break;
          }
          widthAbove++;
        }

        // Find closest width below that results in an integer height
        while (widthBelow > 0) {
          const heightBelow =
            (widthBelow * aspectRatioHeight) / aspectRatioWidth;
          if (Number.isInteger(heightBelow)) {
            break;
          }
          widthBelow--;
        }

        return {
          closestAbove: {
            width: widthAbove,
            height: (widthAbove * aspectRatioHeight) / aspectRatioWidth
          },
          closestBelow: {
            width: widthBelow,
            height: (widthBelow * aspectRatioHeight) / aspectRatioWidth
          }
        };
      }

      if (aspectRatioHeight > 0 && aspectRatioWidth > 0) {
        // It can happen that the returned crop is not 100% the aspect ratio. We are correcting it here
        // even though it means adjust the crop width and height by a few pixels. This is important, because
        // platforms such as Instagram are very picky about the aspect ratio of the images
        const lowerWidth = Math.floor(cropInfo.width);

        const result = closestIntegerHeightWidth(lowerWidth);

        cropInfo.width = result.closestBelow.width;
        cropInfo.height = result.closestBelow.height;
      }

      const cropItem = state.postData[platform].cropList?.find(
        (obj) => obj.uuid === cropInfo.uuid
      );

      if (cropItem) {
        cropItem.width = cropInfo.width;
        cropItem.height = cropInfo.height;
        cropItem.x = cropInfo.x;
        cropItem.y = cropInfo.y;
        cropItem.aspect_ratio_width = cropInfo.aspect_ratio_width;
        cropItem.aspect_ratio_height = cropInfo.aspect_ratio_height;
      } else {
        state.postData[action.payload.platform].cropList = [
          ...state.postData[action.payload.platform].cropList,
          action.payload.cropInfo
        ];
      }

      if (action.payload.platform === ALL) {
        // General media upload has been executed
        for (const p of PLATFORM_LIST) {
          if (state.platformEnabled[p]) {
            const cropItem = state.postData[p].cropInfo.find(
              (obj) => obj.uuid === cropInfo.uuid
            );

            if (cropItem) {
              cropItem.width = cropInfo.width;
              cropItem.height = cropInfo.height;
              cropItem.x = cropInfo.x;
              cropItem.y = cropInfo.y;
              cropItem.aspect_ratio_width = cropInfo.aspect_ratio_width;
              cropItem.aspect_ratio_height = cropInfo.aspect_ratio_height;
            } else {
              state.postData[p].cropList = [
                ...state.postData[p].cropList,
                action.payload.cropInfo
              ];
            }
          }
        }
      }

      state.lastCropUpdateTimestamp = Date.now().toString();
    },

    updateAssetUploadingState: (
      state,
      action: PayloadAction<{
        state: boolean;
      }>
    ) => {
      state.assetUploading = action.payload.state;
    },
    switchPreviewMode: (state, action: PayloadAction<{ mode: string }>) => {
      state.ui.previewMode = action.payload.mode;
    },
    setModalUiContent: (
      state,
      action: PayloadAction<{
        modal: string;
        attribute: string;
        value: any;
      }>
    ) => {
      const { modal, attribute, value } = action.payload;

      state.modalStates[modal][attribute] = value;
    },
    tempToMainScheduleData: (state) => {
      state.time = state.schedulePlanningData.time;
      state.recurrence_as_str = state.schedulePlanningData.recurrence_as_str;
      state.is_recurring_post = state.schedulePlanningData.is_recurring_post;
      state.publication_delay_in_minutes =
        state.schedulePlanningData.publication_delay_in_minutes;

      for (const key in state.publishingDelayData) {
        if (state.temporaryPublishingDelayData.hasOwnProperty(key)) {
          state.publishingDelayData[key] =
            state.temporaryPublishingDelayData[key];
        }
      }

      for (const p of PLATFORM_LIST_WITH_ALL) {
        state.postData[p].planned_publication_datetime_time =
          state.postData[p].tempPublicationData.time;
        state.postData[p].planned_publication_datetime_date =
          state.postData[p].tempPublicationData.date;
        state.postData[p].planned_publication_datetime =
          state.postData[p].tempPublicationData.datetime;
      }
    },

    mainToTempScheduleData: (state) => {
      state.schedulePlanningData.time = state.recurrence_as_str
        ? extractTimeFromRecurrence(state.recurrence_as_str)
        : DEFUALT_INPUT_TIME;
      state.schedulePlanningData.recurrence_as_str = state.recurrence_as_str;
      state.schedulePlanningData.is_recurring_post = state.is_recurring_post;

      state.schedulePlanningData.publication_delay_in_minutes =
        state.publication_delay_in_minutes;

      for (const key in state.temporaryPublishingDelayData) {
        if (state.publishingDelayData.hasOwnProperty(key)) {
          state.temporaryPublishingDelayData[key] =
            state.publishingDelayData[key];
        }
      }

      for (const p of PLATFORM_LIST_WITH_ALL) {
        state.postData[p].tempPublicationData.time =
          state.postData[p].planned_publication_datetime_time;

        state.postData[p].tempPublicationData.date =
          state.postData[p].planned_publication_datetime_date;

        state.postData[p].tempPublicationData.datetime =
          state.postData[p].planned_publication_datetime;
      }
    },
    setShowPostIdeaModal: (state, action: PayloadAction<boolean>) => {
      state.ui.showPostIdeaModal = action.payload;
    },
    setQuillEditors: (state, action: PayloadAction<any>) => {
      state.quillEditors[action.payload.platform] = action.payload.editor;
    }
  }
});

export const {
  setQuillEditors,
  setShowPostIdeaModal,
  updatePlatformEnabled,
  isPlatformEnabled,
  isPlatformSupported,
  updatePostTypeSelected,
  updateUseSeparateMediaFiles,
  updateUseSeparatePublicationDateTime,
  addMediaFiles,
  reorderMediaFiles,
  addMediaUploadingFiles,
  clearUnuploadedFiles,
  addDraggedFiles,
  removeDraggedFiles,
  addMediaLibraryFiles,
  removeMediaLibraryFiles,
  deleteAfile,
  deleteALoadingfile,
  fileUploadStatus,
  addPublicationDate,
  addPublicationTime,
  clearPublicationDateTime,
  addPublicationDateTime,
  addMainPublicationDateTime,
  addTime,
  addPublicationDelay,
  addRecurringStr,
  updatePublishingDelayData,
  clearRecurrenceData,
  clearPublishingDelayData,
  updateRecurringStatus,
  updateUseSeparateTextFields,
  updatePostText,
  addImageThumbnail,
  editImageThumbnail,
  clearImageThumbnail,
  cancelImageThumbnail,
  layerImageThumbnail,
  updateUploadedImagePresets,
  updateLayeredImagePresets,
  updateLayeredImageState,
  updateEditedImageState,
  updateEditorImagePresets,
  updateEditedImagePresets,
  saveEditorFullImage,
  updateThumbnailProcessing,
  updateMediaAssetFile,
  toggleActivePlatform,
  updatePlatformSelectionFilter,
  removeActivePlatform,
  updateTagSelectionFilter,
  addActivePlatform,
  setShowOnlySelectedPlatforms,
  setUploadAreaVisibility,
  resetActiveLocations,
  setShowProfileGroupSelectModal,
  setScheduleModalToggle,
  setShowEditImageModal,
  setGeneralPostType,
  setPlatformFirstComment,
  setSliceAttribute,
  setPostDataAttribute,
  updateMultiImageEditorCloseState,
  updateEditorCloseState,
  updateThumbnailCloseState,
  updateEditorUpdateState,
  updateIGTagModalCloseState,
  updateIGTags,
  deleteATag,
  addIGCollaborator,
  deleteACollaborator,
  saveCroppedImageCache,
  setMediaDataAttribute,
  updateCancelToken,
  toggleActivePlatformTemp,
  removeActivePlatformTemp,
  addActivePlatformTemp,
  resetActiveLocationsTemp,
  syncProfileGroupSelectState,
  addCropObject,
  switchPreviewMode,
  addUnEditedMediaFiles,
  deleteUnEditedMediafile,
  updateAssetUploadingState,
  clearEditedMediafile,
  setModalUiContent,
  tempToMainScheduleData,
  mainToTempScheduleData,
  updatePostKey,
  updateUrlPreviewData,
  updatePostDataKey
} = lomavisCreatorSlice.actions;
export default lomavisCreatorSlice.reducer;

export const getProfileDataForPlatform = (
  platform: string,
  lomavisCreatorConfig: any
) => {
  const { connected_platforms, platform_display_data } = lomavisCreatorConfig;

  if (!connected_platforms[platform]) {
    return {
      platform: PLATFORM_STATIC_ICONS_PNG[platform],
      name: PLATFORM_LIST_NAMES[platform] || platform,
      id: platform
    };
  }

  const profileData = platform_display_data[platform];
  if (profileData) {
    return {
      platform:
        profileData.profile_image_url || PLATFORM_STATIC_ICONS_PNG[platform],
      name: profileData.display_name || PLATFORM_LIST_NAMES[platform],
      id: platform
    };
  }
};
export const trasnferPostToSliceFormat = (post) => {
  const lomavisCreatorConfig = readLomavisCreatorConfig();

  const general_post_type = POST_TYPE_CHOICES_MAP[post.post_type]
    ? POST_TYPE_CHOICES_MAP[post.post_type]
    : POST_TYPE_CHOICES_MAP[POST_TYPE_CHOICE_POST];

  const additionalConfig: any = {
    uuid: post.uuid || '',
    post_status: post.post_status || null,
    use_individual_images: post.use_individual_images || false,
    use_separate_publication_datetime:
      post.use_separate_publication_datetime || false,
    use_separate_text: post.use_separate_text || false,
    useSeparateMediaFiles: post.use_individual_images || false,
    useSeparateTextFields: post.use_separate_text || false,
    creator_multipost_config: {},
    recurrence_as_str: post.recurrence_as_str || '',
    is_recurring_post: post.is_recurring_post || false,
    lomavisCreatorConfig: post.lomavis_creator_config || lomavisCreatorConfig,
    platformEnabled: post?.enabled_platforms || {},
    postIdea: post.post_idea || null,
    general_post_type,
    has_been_published: post.has_been_published || false,
    postTypeSelected: post.postTypeSelected || generatePostTypeMap(post),
    all_platforms_publicaton_datetime: null,
    created_in_lomavis: post.created_in_lomavis || false
  };
  additionalConfig.platformEnabled = PLATFORM_LIST.reduce((acc, platform) => {
    acc[platform] = !!post[`${platform}_post`];
    return acc;
  }, {});

  if (
    post.creator_multipost_config &&
    lomavisCreatorConfig.is_multipost &&
    lomavisCreatorConfig.is_update
  ) {
    const creator_multipost_config_as_json = JSON.parse(
      post.creator_multipost_config
    );
    additionalConfig.creator_multipost_config =
      creator_multipost_config_as_json;

    additionalConfig.multipost_copied_to_profile_group_for_approval =
      post.multipost_use_instagram_default_location;
    additionalConfig.multipost_copied_to_profile_group_for_approval =
      post.multipost_copied_to_profile_group_for_approval;

    additionalConfig.publishingDelayData = JSON.parse(
      post.publication_delay_config || '{}'
    );
    additionalConfig.temporaryPublishingDelayData = JSON.parse(
      post.publication_delay_config || '{}'
    );
  }

  const postData = {};
  PLATFORM_LIST_WITH_ALL.forEach((platform) => {
    const postKey = `${platform}_post`;
    if (post[postKey]) {
      postData[platform] = {
        ...post[postKey],
        uuid: post[postKey]?.uuid || '',
        text: JSON.parse(post[postKey]?.text || '{}'),
        first_comment: JSON.parse(post[postKey]?.first_comment || '{}'),
        instagram_location_id: post[postKey]?.instagram_location_id || null,
        instagram_location_name: post[postKey]?.instagram_location_name || null,
        facebook_location_name: post[postKey]?.facebook_location_name || null,
        facebook_location_id: post[postKey]?.facebook_location_id || null,
        instagram_user_tags: post[postKey]?.instagram_user_tags || [],
        instagram_temp_tags: post[postKey]?.media
          ?.map((obj, index) => {
            if (post[postKey]?.instagram_user_tags.length > 0) {
              const allTags = JSON.parse(post[postKey]?.instagram_user_tags);
              const tags = allTags[index];

              if (tags && tags.length > 0) {
                return { uuid: obj.uuid, tags };
              }
            }
          })
          .filter(Boolean),
        youtube_title: post[postKey]?.youtube_title || '',
        youtube_tags: post[postKey]?.youtube_tags || '',
        youtube_category: post[postKey]?.youtube_category || 0,
        youtube_privacy_status:
          post[postKey]?.youtube_privacy_status || 'public',
        youtube_available_for_kids:
          post[postKey]?.youtube_available_for_kids || 0,
        planned_publication_datetime:
          post[postKey]?.planned_publication_datetime || null,
        google_cta_type: post[postKey]?.google_cta_type || null,
        google_cta_url: post[postKey]?.google_cta_url || null,
        google_post_type: post[postKey]?.google_post_type || 'standard',
        google_post_event_start_datetime:
          post[postKey]?.google_post_event_start_datetime || null,
        google_post_event_end_datetime:
          post[postKey]?.google_post_event_end_datetime || null,
        google_post_event_title: post[postKey]?.google_post_event_title || null,

        video_thumbnail: post[postKey]?.video_thumbnail,
        video_thumbnail_editor: post[postKey]?.video_thumbnail_editor,
        video_thumbnail_background_image:
          post[postKey]?.video_thumbnail_background_image,

        uploadedImageThumbnail: {
          url:
            Object.keys(post[postKey]?.video_thumbnail || {}).length > 0 &&
            (post[postKey]?.video_thumbnail_background_image
              ? post[postKey]?.video_thumbnail_background_image
              : post[postKey]?.media
              ? post[postKey]?.media[0]?.preview_image
              : ''),
          file: null,
          uploadPresets: null,
          uploaded: true
        },
        editedImageThumbnail: {
          url: post[postKey]?.video_thumbnail_editor?.original_asset,
          file: null,
          uploadPresets: post[postKey]?.video_thumbnail_editor?.uploadPresets,
          uploaded: false,
          media: post[postKey]?.video_thumbnail_editor
        },
        fullEditorImage: {
          url: post[postKey]?.video_thumbnail_editor?.original_asset,
          file: null,
          uploadPresets: null,
          uploaded: true,
          currentCrop: null
        },
        layeredImageThumbnail: {
          file: post[postKey]?.video_thumbnail?.original_asset,
          uploadPresets: post[postKey].video_thumbnail?.uploadPresets,
          uploaded: false,
          media: post[postKey].video_thumbnail
        },
        [`${POST_TYPE_TO_PREFIX[post.post_type]}_tags`]: fromArrayStringToArr(
          post[postKey][`${POST_TYPE_TO_PREFIX[post.post_type]}_tags`] || ''
        ),

        media:
          post[postKey]?.media?.map((item) => ({
            media_type: item.media_type,
            file_type: item.file_type,
            preview_image: item.preview_image,
            image_url: item.preview_image,
            uuid: item.uuid,
            name: item?.name,
            thumbnail_image: item.thumbnail_image,
            original_asset: item.original_asset,
            preview: item.preview_image,
            video_duration: item.video_duration,
            video_audio_channels: item.video_audio_channels,
            file_size: item.original_filesize_bytes,
            width: item.width,
            height: item.height,
            contentType: item.content_type,
            source_media_uuid: item.source_media_uuid,
            deleteUrl: item.delete_url,
            uploaded: true,
            isUploading: false,
            newUpload: false,
            type: item.media_type,
            editorUpdateState: false,
            id: item.uuid,
            uploadPreset: item.uploadPreset,
            previewImageURL: item.previewImageURL,
            api_url: item.api_url
          })) || [],
        cropList: post[postKey]?.crop_list || []
      };
      postData[ALL] = postData[platform];
      // postData[ALL].text = JSON.parse(post.all_platforms_text || '{}')
    }
  });

  return {
    postData,
    ...additionalConfig
  };
};

export const loadPost = (post) => (dispatch) => {
  const lomavisCreatorConfig = readLomavisCreatorConfig();
  const parsedPost = trasnferPostToSliceFormat(post);
  dispatch(lomavisCreatorSlice.actions.loadPostToState(parsedPost));

  if (lomavisCreatorConfig.is_update) {
    for (const platform of PLATFORM_LIST_WITH_ALL) {
      const postKey = `${platform}_post`;
      if (post[postKey]) {
        const datetime = post[postKey].planned_publication_datetime;
        if (datetime) {
          dispatch(
            addMainPublicationDateTime({
              platform,
              dateTime: new Date(datetime)
            })
          );
        }
      }
    }
  }

  // Update selected post type for each platform
  for (const platform of PLATFORM_LIST) {
    const postKey = `${platform}_post`;
    if (post[postKey]) {
      // check if platform general post type exist
      if (
        parsedPost.general_post_type &&
        platformFeatures[platform] &&
        platformFeatures[platform][parsedPost.general_post_type]
      )
        for (const index in platformFeatures[platform][
          parsedPost.general_post_type
        ]) {
          const configObject =
            platformFeatures[platform][parsedPost.general_post_type][index];
          const dataFieldEnable = configObject?.dataFieldEnable?.[0];
          if (dataFieldEnable) {
            if (post[postKey][dataFieldEnable] == true) {
              dispatch(
                updatePostTypeSelected({
                  platform,
                  postType: configObject.typeKey
                })
              );
            }
          }
        }
    }
  }
};
export const transferDataToApiFormat = (
  lomavisCreator,
  action = null,
  locations = []
) => {
  // transfer post data to api accepted format
  const postDataToSend: any = {};
  // adding selected Post Types:
  postDataToSend.postTypeSelected = lomavisCreator?.postTypeSelected;
  PLATFORM_LIST_WITH_ALL.forEach((platform) => {
    const postKey = `${platform}_post`;
    if (lomavisCreator?.postData[platform]) {
      if (
        lomavisCreator?.postData[platform]?.media?.length > 0 ||
        lomavisCreator?.postData[platform]?.text
      )
        if (lomavisCreator?.platformEnabled[platform]) {
          postDataToSend[postKey] = { ...lomavisCreator?.postData?.[platform] };

          postDataToSend[postKey][
            `${POST_TYPE_TO_PREFIX[lomavisCreator.general_post_type]}_tags`
          ] = fromArrayToArrString(
            lomavisCreator.postData[platform][
              `${POST_TYPE_TO_PREFIX[lomavisCreator.general_post_type]}_tags`
            ] || []
          );
          // adjust media
          postDataToSend[postKey].media = postDataToSend[postKey].media.map(
            (media) => {
              if (!media.uploadPreset) return media;
              const newMedia = { ...media };
              newMedia.preview =
                newMedia?.uploadPreset?.uploaded_object_data.preview_image_url;
              newMedia.preview_image =
                newMedia?.uploadPreset?.uploaded_object_data.preview_image_url;
              newMedia.image_url =
                newMedia?.uploadPreset?.uploaded_object_data.preview_image_url;
              return newMedia;
            }
          );

          postDataToSend[postKey].video_thumbnail_background_image = null;

          // stringify the text
          postDataToSend[postKey].text = JSON.stringify(
            lomavisCreator?.postData?.[platform]?.text
          );

          postDataToSend[postKey].crop_list = postDataToSend[postKey]?.cropList;

          postDataToSend[postKey].first_comment = lomavisCreator?.postData?.[
            platform
          ]?.first_comment
            ? JSON.stringify(
                lomavisCreator?.postData?.[platform]?.first_comment
              )
            : null;

          postDataToSend[postKey].youtube_tags = JSON.stringify(
            lomavisCreator?.postData?.[platform]?.youtube_tags
          );
          // remove null values
          for (const key in postDataToSend[postKey]) {
            if (postDataToSend[postKey][key] === null) {
              delete postDataToSend[postKey][key];
            }
          }

          // this moves the temp intagram tags to the

          const initialTagArray = Array(10).fill([]);

          lomavisCreator?.postData?.[platform]?.instagram_temp_tags?.forEach(
            (obj) => {
              // Find the index of the media file corresponding to the tag's uuid
              const itemIndex = postDataToSend[postKey].media.findIndex(
                (item) => item?.uuid === obj?.uuid
              );

              // Ensure the index is valid (i.e., media item with that uuid exists)
              if (itemIndex !== -1) {
                // Assign the tags to the corresponding index in the initialTagArray
                initialTagArray[itemIndex] = obj.tags;
              }
            }
          );

          postDataToSend[postKey].instagram_user_tags =
            JSON.stringify(initialTagArray);
        }
    }
  });
  // add other keys
  postDataToSend.use_individual_images = lomavisCreator.useSeparateMediaFiles;
  postDataToSend.use_separate_publication_datetime =
    lomavisCreator.use_separate_publication_datetime;
  postDataToSend.use_separate_text = lomavisCreator.useSeparateTextFields;

  postDataToSend.publication_delay_config = JSON.stringify(
    lomavisCreator.publishingDelayData
  );

  postDataToSend.creator_multipost_config = JSON.stringify(
    lomavisCreator.creator_multipost_config
  );
  postDataToSend.recurrence_as_str = lomavisCreator.recurrence_as_str;
  postDataToSend.multipost_use_instagram_default_location =
    lomavisCreator.multipost_use_instagram_default_location;
  postDataToSend.multipost_copied_to_profile_group_for_approval =
    lomavisCreator.multipost_copied_to_profile_group_for_approval;
  postDataToSend.is_recurring_post = lomavisCreator.is_recurring_post;
  postDataToSend.lomavis_creator_config = lomavisCreator.lomavisCreatorConfig;
  postDataToSend.action = action ? ACTIONS_VALUES_MAP[action] : null;
  postDataToSend.enabled_platforms = lomavisCreator.platformEnabled;
  postDataToSend.is_multipost =
    lomavisCreator.lomavisCreatorConfig.is_multipost;
  postDataToSend.cloud_uuid = lomavisCreator.lomavisCreatorConfig?.cloud?.uuid;
  postDataToSend.location_uuid =
    lomavisCreator.lomavisCreatorConfig?.location?.uuid;
  postDataToSend.all_platforms_text = JSON.stringify(
    lomavisCreator.postData[ALL].text
  );
  // flip POST_TYPE_CHOICES_MAP to get the key by value
  postDataToSend.post_type = Object.keys(POST_TYPE_CHOICES_MAP).find(
    (key) => POST_TYPE_CHOICES_MAP[key] === lomavisCreator.general_post_type
  );
  postDataToSend.copied_from_uuid =
    lomavisCreator.lomavisCreatorConfig?.copied_from;

  if (locations) {
    postDataToSend.creator_multipost_config_metadata = [];

    const enabledLocations = new Set();
    for (const key of Object.keys(
      lomavisCreator.creator_multipost_config || {}
    )) {
      if (lomavisCreator.creator_multipost_config[key]) {
        const uuid = key.split('_')[0];
        enabledLocations.add(uuid);
      }
    }

    if (locations) {
      enabledLocations.forEach((enabledLocation) => {
        const location = locations.find((loc) => loc.uuid === enabledLocation);
        if (!location) return;
        postDataToSend.creator_multipost_config_metadata.push({
          uuid: location.uuid,
          name: location.name,
          slug: location.slug
        });
      });
    }
  }

  return postDataToSend;
};

export const sendPostData = async (lomavisCreator, action, locations) => {
  const postDataToSend = transferDataToApiFormat(
    lomavisCreator,
    action,
    locations
  );
  return await new Promise<any>(async (resolve_promise, reject) => {
    try {
      let response;
      if (lomavisCreator.lomavisCreatorConfig?.is_update) {
        response = await axiosClient.put(
          `${lomavisCreator?.lomavisCreatorConfig.http_endpoint}?is_multipost=${
            lomavisCreator?.lomavisCreatorConfig.is_multipost
          }&location=${
            lomavisCreator?.lomavisCreatorConfig.location != null
          }&cloud=${lomavisCreator?.lomavisCreatorConfig.cloud != null}`,
          postDataToSend
        );
      } else {
        response = await axiosClient.post(
          lomavisCreator?.lomavisCreatorConfig.http_endpoint,
          postDataToSend
        );
      }
      resolve_promise(response.data);
    } catch (e) {
      reject(e);
    }
  });
};

function generatePostTypeMap(post) {
  const postTypeMap = {};
  // set all platforms to post type default
  for (const p of PLATFORM_LIST_WITH_ALL) {
    postTypeMap[p] = POST_TYPE_POST_KEY;
  }

  // Instagram Logic
  if (post.is_instagram_story) {
    postTypeMap[INSTAGRAM] = POST_TYPE_STORY_KEY;
  } else if (post.is_instagram_reel) {
    postTypeMap[INSTAGRAM] = POST_TYPE_REEL_KEY;
  }

  // Facebook Logic
  if (post.is_facebook_story) {
    postTypeMap[FACEBOOK] = POST_TYPE_STORY_KEY;
  } else if (post.is_facebook_reel) {
    postTypeMap[FACEBOOK] = POST_TYPE_REEL_KEY;
  }

  // YouTube Logic
  if (post.is_youtube_short) {
    postTypeMap[YOUTUBE] = POST_TYPE_SHORT_VIDEO_KEY;
  } else {
    postTypeMap[YOUTUBE] = POST_TYPE_VIDEO_KEY;
  }

  // WhatsApp Logic
  if (post.is_whatsapp_status) {
    postTypeMap[WHATSAPP] = POST_TYPE_STATUS;
  } else {
    postTypeMap[WHATSAPP] = POST_TYPE_MESSAGE;
  }

  return postTypeMap;
}
