// lomavisConfig.ts

export interface Location {
  uuid: string;
  slug: string;
  name: string;
}
export interface MediaLibrary {
  enabled: boolean;
  url: string;
}

export interface ConnectedPlatforms {
  facebook: boolean;
  facebook_group: boolean;
  instagram: boolean;
  google: boolean;
  twitter: boolean;
  linkedin_personal_account: boolean;
  linkedin_business_page: boolean;
  youtube: boolean;
  tiktok_personal_account: boolean;
  tiktok_business_page: boolean;
  whatsapp: boolean;
  pinterest: boolean;
  generic: boolean;
}

export interface PlatformDisplayData {
  display_name: string | null;
  profile_image_url: string | null;
}

export interface MentionSearchUrls {
  facebook: string;
  twitter: string;
}

export interface PlaceSearchUrls {
  facebook: string;
}

export interface Features {
  recurring_posts: boolean;
  delayed_posts: boolean;
  publish_to_approval: boolean;
}

export interface lomavisCreatorDefaultData {
  instagram_location_id?: string;
  instagram_location_name?: string;
  facebook_location_id?: string;
  facebook_location_name?: string;
}

export interface LomavisCreatorConfig {
  location: Location;
  media_library: MediaLibrary;
  cloud: any;
  is_multipost: boolean;
  connected_platforms: ConnectedPlatforms;
  supported_platforms: ConnectedPlatforms;
  load_media_files: any[];
  platform_display_data: {
    facebook: PlatformDisplayData;
    facebook_group: PlatformDisplayData;
    instagram: PlatformDisplayData;
    google: PlatformDisplayData;
    twitter: PlatformDisplayData;
    linkedin_personal_account: PlatformDisplayData;
    linkedin_business_page: PlatformDisplayData;
    youtube: PlatformDisplayData;
    tiktok_personal_account: PlatformDisplayData;
    tiktok_business_page: PlatformDisplayData;
    whatsapp: PlatformDisplayData;
    pinterest: PlatformDisplayData;
    generic: PlatformDisplayData;
  };
  mention_search_urls: MentionSearchUrls;
  place_search_urls: PlaceSearchUrls;
  timezone: string;
  features: Features;
  http_endpoint: string;
  is_update: boolean;
  importing_post: boolean;
  creator_defaults?: lomavisCreatorDefaultData;
}

export interface ILomavisCreatorConfigMetadata {
  uuid: string;
  name: string;
  slug: string;
}

export type SchedulePlanningDataType = {
  time: string;
  recurrence_as_str: string;
  is_recurring_post: boolean;
  publication_delay_in_minutes?: number;
};

export type publicationDelayDataType = {
  days: null | number;
  hours: null | number;
  minutes: null | number;
  totalMinutes: null | number;
  noPublishDays: Array<string>;
  noPublishStartTime: string;
  noPublishEndTime: string;
};

export function readLomavisCreatorConfig(): LomavisCreatorConfig | null {
  const scriptTag = document.getElementById("lomavis_creator_config");
  if (scriptTag) {
    const jsonString = scriptTag.textContent;
    if (jsonString) {
      try {
        const config: LomavisCreatorConfig = JSON.parse(jsonString);
        return config;
      } catch (e) {
        console.error("Error parsing JSON", e);
        return null;
      }
    }
  }
  return null;
}
